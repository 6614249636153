document.addEventListener('DOMContentLoaded', () => {
  const hamburgerMenu = document.getElementById('hamburger-menu');

  if (hamburgerMenu === null) {
    return;
  }

  hamburgerMenu.style.paddingTop = getComputedStyle(document.querySelector('.header')).height;

  document.querySelector('.header__hamburger-button').addEventListener('click', () => {
    if (hamburgerMenu.style.display.length) {
      hamburgerMenu.style.display = '';
      document.body.style.overflow = 'hidden';
      window.scroll({ top: 0, behavior: 'instant' });
    } else {
      hamburgerMenu.style.display = 'none';
      document.body.style.overflow = '';
    }
  });
});




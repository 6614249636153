document.querySelectorAll('.dropdown').forEach(dropdown => {
  const button = dropdown.querySelector('.dropdown-button');
  button.addEventListener('click', () => dropdown.classList.toggle('dropdown_expanded'));

  dropdown.querySelectorAll('.dropdown-menu__item').forEach(item => {
    item.addEventListener('click', () => dropdown.classList.toggle('dropdown_expanded', false));
  });

  window.addEventListener('click', event => {
    if (dropdown.classList.contains('dropdown_expanded') && !event.composedPath().includes(dropdown)) {
      dropdown.classList.toggle('dropdown_expanded', false);
    }
  });
});

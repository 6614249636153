document.querySelectorAll('.digital-body-item-carousel').forEach(carouselElement => {
  new Swiper(carouselElement, {
    loop: true,

    wrapperClass: 'digital-body-item-carousel__wrapper',
    slideClass: 'digital-body-item-carousel-slide',

    navigation: {
      prevEl: '.digital-body-item-carousel-buttons__item_previous',
      nextEl: '.digital-body-item-carousel-buttons__item_next'
    }
  });
});

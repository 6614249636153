document.querySelectorAll('.tabs').forEach(tabsElement => {
  let activeId = tabsElement.dataset.activeId;

  const updateTabs = () => {
    tabsElement.querySelectorAll('.tabs__item').forEach(tabsElementItem => tabsElementItem.style.display = 'none');
    if (activeId === undefined) {
      return;
    }

    const tabsItemElement = tabsElement.querySelector(`.tabs__item[data-id="${activeId}"]`);
    if (tabsItemElement !== null) {
      tabsItemElement.style.display = '';
    }
  };

  tabsElement.querySelectorAll('.tabs-header__button').forEach(tabsButtonElement => {
    tabsButtonElement.addEventListener('click', () => {
      if (tabsButtonElement.dataset.id !== undefined) {
        activeId = tabsButtonElement.dataset.id;
        tabsElement.setAttribute('data-active-id', activeId);
        updateTabs();
      }
    });
  });

  updateTabs();
});

document.querySelectorAll('.article-carousel').forEach(carouselElement => {
  Fancybox.bind('[data-fancybox]');

  new Swiper(carouselElement, {
    loop: true,

    wrapperClass: 'article-carousel__wrapper',
    slideClass: 'article-carousel__slide',

    slidesPerView: 'auto',
    centeredSlides: true,

    spaceBetween: 30,

    breakpoints: {
      0: {
        slidesPerView: 2,
        spaceBetween: 15
      },
      768: {
        slidesPerView: 'auto',
        spaceBetween: 30
      }
    }
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const resize = () => {
    document.querySelectorAll('.hero').forEach(heroElement => {
      const heroCardsElement = heroElement.querySelector('.hero-cards');

      if (heroCardsElement === null) {
        return;
      }

      if (window.outerWidth > 960) {
        heroElement.querySelector('.container').style.marginBottom =
          `calc(${parseFloat(getComputedStyle(heroCardsElement).height) / 2}px + var(--block-space-between))`;

        heroElement.querySelector('.hero-cards-wrapper').style.paddingTop =
          `calc(${parseFloat(getComputedStyle(heroCardsElement).height) / 2}px + var(--padding-bottom))`;
      } else {
        heroElement.querySelector('.container').style.marginBottom = '';
        heroElement.querySelector('.hero-cards-wrapper').style.paddingTop = '';
      }
    });
  };

  window.addEventListener('resize', resize);
  resize();
});
